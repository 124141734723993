import React, { useEffect, useState } from "react"
import Root from "../components/root"
import classes from "./success.module.css"
import SuccessCheck from "../assets/svg/check-circle-solid.svg"
import { Link } from "gatsby"
import { send } from "emailjs-com"

const Success = () => {
  var data =
    typeof window !== "undefined" &&
    localStorage &&
    JSON.parse(localStorage.getItem("data"))

  var toSend = {
    from_name: "dev.madjon@gmail.com",
    to_name: "madjon.visuals@gmail.com",
    subject: "MadJon Questionnaire Data",
    message: JSON.stringify(data, null, 4),
    reply_to: "",
  }

  function sendEmail() {
    send(
      "service_huuu7kf",
      "template_p1hwcpj",
      toSend,
      "user_EyCBtsznF7DQz8b3hcVez"
    )
      .then(response => {
        console.log("SUCCESS!", response.status, response.text)
      })
      .catch(err => {
        console.log("FAILED...", err)
      })
  }

  useEffect(() => {
    setTimeout(() => {
      sendEmail()
      // navigate("/video-editing")
    }, 2000)
  }, [data])

  return (
    <>
      <Link to="/video-editing" className="pointer ml-2">
        Back
      </Link>
      <Root className={classes.root}>
        <SuccessCheck className={classes.success} />
        <div className="mt-8 text-xl md:text-2xl font-semibold">
          Payment Successful!
        </div>
      </Root>
    </>
  )
}

export default Success
